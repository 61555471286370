.srm-modal-open {
  position: relative;
  /*overflow-y: hidden;*/
}

.srm-fade-in {
  animation: fadeIn 0.1s forwards
}

.srm-slide-down-fade {
  animation: slideDownFade 0.2s forwards
}

.srm-modal-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5)
}

.srm-modal-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  overflow: auto;
  position: fixed;
  align-items: center
}

.srm-modal-content {
  margin: auto;
  height: 100vh;
  overflow: auto;
  position: relative;
  background: white;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 640px) {
  .srm-modal-content {
    height: auto;
    overflow: none;
  }
}

@media screen and (max-width: 640px) {
  .srm-modal-content {
    padding: 2rem 4rem !important;
    width: 100%;
    height: auto;
  }
}

.srm-close-icon {
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  color: gray;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 8px;
  position: absolute;
}

.srm-close-icon:hover {
  color: red
}

.srm-modal-content, .modal-like {
  border-radius: 1rem;
  padding: 2rem 4rem;
  background: linear-gradient(124deg, #FFAEDB, #FFB7CE, #F6E295, #B9FDC4, #BAECF8, #A6ABF6, #A4A4F5);
  background-size: 1000% 130%;
  box-shadow: 0 8px 8px 1px rgba(0, 0, 0, .3);
  animation: rainbow 22s ease infinite;
}

.srm-close-icon line {
  opacity: 0;
}

@keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes slideDownFade {
  0% {
    opacity: 0;
    transform: translateY(-20px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}